var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style.cardRoot},[_c('nuxt-link',{class:( _obj = {}, _obj[_vm.$style.originProduct] = !_vm.isBuyTogether, _obj ),attrs:{"to":_vm.localePath(_vm.getProductPath(_vm.product))}},[(!_vm.isBuyTogether)?_c('p',{class:_vm.$style.originProductText},[_vm._v("\n      "+_vm._s(_vm.$t('Item you’re currently viewing'))+"\n    ")]):_vm._e(),_vm._v(" "),(_vm.product.small_image)?_c('SimpleImage',{class:_vm.$style.image,attrs:{"src":_vm.product.small_image.url,"alt":_vm.product.name}}):_vm._e(),_vm._v(" "),(_vm.isBuyTogether)?_c('p',{class:_vm.$style.name},[_vm._v("\n      "+_vm._s(_vm.sanitiseName(_vm.product.name))+"\n    ")]):_vm._e()],1),_vm._v(" "),_c('div',{class:_vm.$style.price},[(!_vm.isBuyTogether)?_c('ProductPrice',{attrs:{"prices":_vm.product.price,"special-price":_vm.product.special_price,"is-price-bold":false}}):[_c('p',[_vm._v("\n        "+_vm._s(_vm.$t('Normal price: {value}', { value: _vm.$fc(_vm.getCalculatedPrice(_vm.product.price.minimalPrice)) }))+"\n      ")]),_vm._v(" "),_c('p',{class:_vm.$style.specialOffer},[_vm._v("\n        "+_vm._s(_vm.$t('Special offer price: {value}', { value: _vm.$fc(Number.parseFloat(_vm.getCalculatedPrice(_vm.product.price.minimalPrice)) - _vm.discountValue) }))+"\n      ")])]],2),_vm._v(" "),(_vm.isBuyTogether)?_c('Yields',{attrs:{"product":_vm.product,"yields-type":'product',"props-styles":{
      yieldsList: _vm.$style.yieldsList,
      yieldsTitle: _vm.$style.yieldsTitle,
      yieldItem: _vm.$style.yieldItem,
      yield: _vm.$style.yield
    }}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }













































import {
  computed,
  defineComponent, PropType, reactive,
} from '@nuxtjs/composition-api';

import SimpleButton from '~/components/common/SimpleButton.vue';
import { MODAL_NAMES } from '~/components/common/SimpleModal';
import { useCart, useTax, useUiState } from '~/composables';
import { ProductInterface } from '~/modules/GraphQL/types';

import ProductCard from './components/ProductCard.vue';

export default defineComponent({
  name: 'BuyTogetherProduct',
  components: {
    ProductCard,
    SimpleButton,
  },
  props: {
    product: {
      type: [Object, null] as PropType<ProductInterface | null>,
      default: null,
    },
  },

  setup(props) {
    const { getCalculatedPrice, vatLabel } = useTax();
    const { addBuyTogetherItems, error } = useCart();
    const { toggleModal } = useUiState();

    const data = reactive({
      BUY_TOGETHER_DISCOUNT: 0.2,
      buttonLabel: 'Add all items to Basket',
      isProductDisabled: false,
    });

    const productPrice = computed(() => Number.parseFloat(getCalculatedPrice(props.product.price.minimalPrice)));
    const buyTogetherPrice = computed(() => Number.parseFloat(getCalculatedPrice(props.product.buy_together.price.minimalPrice)));
    const discount = computed(() => buyTogetherPrice.value * data.BUY_TOGETHER_DISCOUNT);
    const price = computed(() => productPrice.value + buyTogetherPrice.value * (1 - data.BUY_TOGETHER_DISCOUNT));

    const handleClick = async () => {
      data.isProductDisabled = true;
      await addBuyTogetherItems({ mainProductSku: props.product.sku, additionalProductSku: props.product.buy_together.sku });
      data.isProductDisabled = false;
      toggleModal({
        identifier: MODAL_NAMES.afterAddToCart,
        props: {
          product: props.product,
          qty: 1,
          isBuyTogether: true,
        },
      });
    };

    return {
      data,
      discount,
      price,
      vatLabel,
      error,
      handleClick,
    };
  },
});

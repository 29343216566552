


























































import {
  defineComponent, PropType,
} from '@nuxtjs/composition-api';

import { SimpleImage } from '~/components/common/SimpleImage';
import ProductPrice from '~/components/Price.vue';
import Yields from '~/components/Yields/Yields.vue';
import { useTax } from '~/composables';
import { getProductPath } from '~/helpers/productHelpers';
import { ProductInterface } from '~/modules/GraphQL/types';

export default defineComponent({
  name: 'ProductCard',
  components: {
    SimpleImage,
    ProductPrice,
    Yields,
  },
  props: {
    product: {
      type: [Object, null] as PropType<ProductInterface | null>,
      default: null,
    },
    isBuyTogether: {
      type: Boolean,
      default: false,
    },
    discountValue: {
      type: Number,
      default: 0,
    },
  },

  setup() {
    const { rawPriceCalculation, getCalculatedPrice } = useTax();

    const sanitiseName = (name: string): string => name.replace(/ *\([^)]*\) */g, '').replace(' -', '');

    return {
      sanitiseName,
      getProductPath,
      rawPriceCalculation,
      getCalculatedPrice,
    };
  },
});
